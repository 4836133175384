import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import lottie from "lottie-web";

import { Card } from "react-bootstrap";
import HomeHero from "../components/home-hero";
import StarLogo from "../images/icons/star.svg";
import FacebookLogo from "../images/bi_facebook.svg";
import XLogo from "../images/logo 1.svg";
import InstaLogo from "../images/Frame 62.svg";
import ContactForm from "../components/contactForm";
import HeroOther from "../components/hero-other";
import ScorecardModal from "../components/scorecardModal";

const GreenFeesPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "green-fees-1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img1: wpMediaItem(title: { eq: "Pantry" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img2: wpMediaItem(title: { eq: "Tanishq" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img3: wpMediaItem(title: { eq: "Contact-New" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery1: wpMediaItem(title: { eq: "green-fees-2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery2: wpMediaItem(title: { eq: "green-fees-3" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery3: wpMediaItem(title: { eq: "green-fees-4" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery4: wpMediaItem(title: { eq: "green-fees-5" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery5: wpMediaItem(title: { eq: "green-fees-6" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery6: wpMediaItem(title: { eq: "green-fees-7" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery7: wpMediaItem(title: { eq: "green-fees-8" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			scorecard: wpMediaItem(title: { eq: "Scorecard-good-quality" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			# allInstagramContent(limit: 6, sort: { fields: timestamp, order: DESC }) {
			#   nodes {
			#     localFile {
			#       childImageSharp {
			#         gatsbyImageData(
			#           formats: [AUTO, WEBP]
			#           quality: 100
			#           transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			#           layout: CONSTRAINED
			#           placeholder: BLURRED
			#         )
			#       }
			#     }
			#     caption

			#     permalink
			#     timestamp
			#   }
			# }

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Green Fees",
				item: {
					url: `${siteUrl}/green-fees`,
					id: `${siteUrl}/green-fees`,
				},
			},
		],
	};

	const scorecardImage = data.scorecard;

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Green Fees | Toot Hill Golf Course"
				description="Enjoy exceptional golf without compromise. We never use temporary tees and our greens are playable throughout the year."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/green-fees`,
					title: "Green Fees | Toot Hill Golf Course",
					description:
						"Enjoy exceptional golf without compromise. We never use temporary tees and our greens are playable throughout the year.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<HeroOther
						smallHeading="Our Fees"
						title="Green fees"
						description={
							<>
								Enjoy exceptional golf without compromise. We never use
								<br className="d-none d-md-block" />
								temporary tees and our greens are playable throughout
								<br className="d-none d-md-block" /> the year.
							</>
						}
						externalLink
						button1Text="Reserve a Tee Time"
						button1Link="https://bookings.toothillgolfclub.co.uk/"
						button2Text="Course overview"
						button2Link="/course-overview"
						backgroundImage={
							data.heroImg.localFile.childImageSharp.gatsbyImageData
						}
					/>
					<section className="py-5 bg-white py-lg-7">
						<Container>
							<Row className="g-5 gx-lg-7 align-items-center justify-content-center">
								<Col lg={8}>
									<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
										prices
									</p>
									<h2 className="line-heading-dark text-center mb-5 mb-lg-7 fs-1">
										Green fee rates
									</h2>
								</Col>
							</Row>
							<Row>
								<Col>
									<Row className="g-5  ">
										{/* <p className="fs-5 mt-5 text-primary text-uppercase">
											Our rates are as follows:
										</p> */}
										{/* Once winter raters are added back in this is the correct intro for summer rates */}
										{/* <p className="fs-5 mt-5 text-primary text-uppercase">
											Our Summer rates run from 1st April to the 31st October
											are as follows:
										</p> */}
										<Col
											className="text-center "
											style={{ minHeight: "100%" }}
											xl={4}
										>
											<div className="  text-center  ">
												<h3 className="text-uppercase text-dark-green fs-5 pb-3">
													Monday to Thursday
												</h3>
											</div>
											<div
												style={{ height: "1px" }}
												className="bg-dark-green mx-auto mb-3 w-60"
											></div>
											<h4 className="ssp-bold fs-6 text-dark-green">
												6:30am to Midday
											</h4>
											<p>£35</p>
											<h4 className="ssp-bold mt-4 fs-6 text-dark-green">
												Midday to 3:00pm
											</h4>
											<p>£30</p>
											<h4 className="ssp-bold mt-4 fs-6 text-dark-green">
												3:00pm Onwards
											</h4>
											<p>£25</p>
										</Col>
										<Col
											className="text-center "
											style={{ minHeight: "100%" }}
											xl={4}
										>
											<div className="  text-center  ">
												<h3 className="text-uppercase text-dark-green fs-5 pb-3">
													Friday
												</h3>
											</div>
											<div
												style={{ height: "1px" }}
												className="bg-dark-green mx-auto mb-3 w-60"
											></div>
											<h4 className="ssp-bold fs-6 text-dark-green">
												6:30am to Midday
											</h4>
											<p>£45</p>
											<h4 className="ssp-bold mt-4 fs-6 text-dark-green">
												Midday to 3:00pm
											</h4>
											<p>£35</p>
											<h4 className="ssp-bold mt-4 fs-6 text-dark-green">
												3:00pm Onwards
											</h4>
											<p>£30</p>
										</Col>
										<Col
											className="text-center "
											style={{ minHeight: "100%" }}
											xl={4}
										>
											<div className="  text-center  ">
												<h3 className="text-uppercase text-dark-green fs-5 pb-3">
													Weekends & Bank Holidays
												</h3>
											</div>
											<div
												style={{ height: "1px" }}
												className="bg-dark-green mx-auto mb-3 w-60"
											></div>
											<h4 className="ssp-bold fs-6 text-dark-green">
												6:30am to Midday
											</h4>
											<p>£50</p>
											<h4 className="ssp-bold mt-4 fs-6 text-dark-green">
												Midday to 3:00pm
											</h4>
											<p>£40</p>
											<h4 className="ssp-bold mt-4 fs-6 text-dark-green">
												3:00pm Onwards
											</h4>
											<p>£35</p>
										</Col>
									</Row>
									{/* Winter rates are commented out and can be added back by uncommenting the below block */}
									{/* <Row className="g-5 py-5">
										<p className="fs-5 mt-5 text-primary text-uppercase">
											Our winter rates from 4th December 2023 to the 29th March
											2024 are as follows:
										</p>
										<Col
											className="text-center "
											style={{ minHeight: "100%" }}
											xl={4}
										>
											<div className="  text-center  ">
												<h3 className="text-uppercase text-dark-green fs-5 pb-3">
													Monday to Thursday
												</h3>
											</div>
											<div
												style={{ height: "1px" }}
												className="bg-dark-green mx-auto mb-3 w-60"
											></div>
											<h4 className="ssp-bold fs-6 text-dark-green">
												Any time
											</h4>
											<p>£30</p>
										</Col>
										<Col
											className="text-center "
											style={{ minHeight: "100%" }}
											xl={4}
										>
											<div className="  text-center  ">
												<h3 className="text-uppercase text-dark-green fs-5 pb-3">
													Friday
												</h3>
											</div>
											<div
												style={{ height: "1px" }}
												className="bg-dark-green mx-auto mb-3 w-60"
											></div>
											<h4 className="ssp-bold fs-6 text-dark-green">
												6:30am to Midday
											</h4>
											<p>£40</p>
											<h4 className="ssp-bold mt-4 fs-6 text-dark-green">
												Midday onwards
											</h4>
											<p>£30</p>

										</Col>
										<Col
											className="text-center "
											style={{ minHeight: "100%" }}
											xl={4}
										>
											<div className="  text-center  ">
												<h3 className="text-uppercase text-dark-green fs-5 pb-3">
													Weekends & Bank Holidays
												</h3>
											</div>
											<div
												style={{ height: "1px" }}
												className="bg-dark-green mx-auto mb-3 w-60"
											></div>
											<h4 className="ssp-bold fs-6 text-dark-green">
												6:30am to Midday
											</h4>
											<p>£45</p>
											<h4 className="ssp-bold mt-4 fs-6 text-dark-green">
												Midday onwards
											</h4>
											<p>£30</p>

										</Col>
									</Row>
									<Row className="justify-content-center">
										<Col>
											<p className="text-center mt-4 text-primary text-uppercase">
												Winter Rates (Monday to Friday) include a coffee and
												bacon roll
											</p>
										</Col>
									</Row> */}
								</Col>
							</Row>
							<Row>
								<Col className="text-center mt-5 mt-lg-7">
									<Button
										style={{ borderRadius: "0px" }}
										variant="primary"
										href="https://bookings.toothillgolfclub.co.uk/"
										target="_blank"
										rel="noreferrer"
										className="py-3 w-100 text-white w-md-auto mb-4 mb-md-0 px-5 "
									>
										Reserve a tee time
									</Button>
									{/* <p className="fs-5 mt-5 text-primary text-uppercase">
										Buggies are now available to hire for £30 Please call the
										proshop on <a href="tel:01277365747">01277 365747</a> to
										book.
									</p> */}
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 bg-primary py-lg-7">
						<Container>
							<Row className="g-5 gx-lg-7">
								<Col lg={5}>
									<p className="text-uppercase  text-secondary pb-2 mb-0 ssp-semibold">
										18 hole golf course
									</p>
									<h2 className=" text-white mb-3 ">
										Course information about Toot Hill Golf Course
									</h2>
									<p className="text-white">
										The condition of the course and the maturity of the trees
										give the impression that the course is many years older than
										it actually is. Construction of the course started in 1989
										and it was open for play in September 1991.
									</p>
									{/* <Button
										style={{ borderRadius: "0px" }}
										variant="outline-white"
										as={Link}
										to="/"
										className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-3 px-6 "
									>
										Scorecard
									</Button> */}
									<ScorecardModal image={scorecardImage} green />
								</Col>
								<Col lg={7}>
									<Row className="g-0">
										<Col
											className="text-center"
											style={{ borderRight: "1px solid white" }}
											xs={6}
										>
											<p className="display-5 pb-0 mb-0 text-white merriweather">
												18
											</p>
											<p className="fs-2 text-white pb-0 mb-0">Holes</p>
											<div
												className="my-4 w-100 bg-white"
												style={{ height: "1px" }}
											></div>
											<p className="display-5 pb-0 mb-0 text-white merriweather">
												6254
											</p>
											<p className="fs-2 text-white">Yards</p>
										</Col>
										<Col className="text-center" xs={6}>
											<p className="display-5 pb-0 mb-0 text-white merriweather">
												70
											</p>
											<p className="fs-2 text-white pb-0 mb-0">Par</p>
											<div
												className="my-4 w-100 bg-white"
												style={{ height: "1px" }}
											></div>
											<p className="display-5 pb-0 mb-0 text-white merriweather">
												1991
											</p>
											<p className="fs-2 text-white">Opened</p>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row>
								<Col lg={6}>
									<h2 className="text-primary mb-4 fs-1">The course</h2>
									<p>
										Our golf course is an all-year-round gem, welcoming golfers
										of all abilities. With a par of 70, it provides an enjoyable
										challenge for both newcomers and seasoned players.
										Impeccably maintained fairways and greens ensure an
										exceptional golfing experience all year round.
									</p>
									<p className="mb-4">
										Join us for a round anytime and relish the timeless joy of
										golf.
									</p>
								</Col>
							</Row>
							<Row className="d-none d-md-flex">
								<Col md={6} lg={4} className="g-3">
									<GatsbyImage
										style={{ height: "636px" }}
										className="w-100 mb-3 d-lg-none"
										image={
											data.gallery6.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery6.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "310px" }}
										image={
											data.gallery1.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery1.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "310px" }}
										image={
											data.gallery2.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery2.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3 d-none d-lg-block"
										style={{ height: "310px" }}
										image={
											data.gallery3.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery3.altText}
									/>
								</Col>
								<Col md={6} lg={4} className="g-3">
									<GatsbyImage
										className="w-100 mb-3 d-lg-none"
										style={{ height: "310px" }}
										image={
											data.gallery3.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery3.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "310px" }}
										image={
											data.gallery4.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery4.altText}
									/>
									<GatsbyImage
										style={{ height: "636px" }}
										className="w-100 mb-3"
										image={
											data.gallery5.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery5.altText}
									/>
								</Col>
								<Col lg={4} className="g-3 d-none d-lg-block">
									<GatsbyImage
										style={{ height: "636px" }}
										className="w-100 mb-3"
										image={
											data.gallery6.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery6.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "310px" }}
										image={
											data.gallery7.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery7.altText}
									/>
								</Col>
							</Row>
							<Row className=" d-md-none">
								<Col xs={6} lg={4} className="g-3">
									<GatsbyImage
										style={{ height: "336px" }}
										className="w-100 mb-3 d-lg-none"
										image={
											data.gallery6.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery6.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "160px" }}
										image={
											data.gallery1.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery1.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "160px" }}
										image={
											data.gallery2.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery2.altText}
									/>
								</Col>
								<Col xs={6} lg={4} className="g-3">
									<GatsbyImage
										className="w-100 mb-3 d-lg-none"
										style={{ height: "160px" }}
										image={
											data.gallery3.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery3.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "160px" }}
										image={
											data.gallery4.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery4.altText}
									/>
									<GatsbyImage
										style={{ height: "336px" }}
										className="w-100 mb-3"
										image={
											data.gallery5.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery5.altText}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									{" "}
									<div className="text-center">
										<Button
											style={{ borderRadius: "0px" }}
											variant="outline-primary"
											as={Link}
											to="/course-overview"
											className="py-3 w-100  w-md-auto mb-4 mb-md-0 mt-4 px-6 "
										>
											Course overview
										</Button>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default GreenFeesPage;
